<template>
<v-container class="mt-4">
    <payment-section ></payment-section>
  </v-container>
</template>

<script>
import PaymentSection from '@/components/services/PaymentSection.vue';

export default {
  name: 'PaymentInformationView',
  components: {
    PaymentSection,
  },
};
</script>
